/* js/component/app/App.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import HtmlHead from "../html/HtmlHead.svelte";
import HtmlPage from "../html/HtmlPage.svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-caqnf-style";
	style.textContent = "body{color:#fff;font-family:\"Avenir\", -apple-system, BlinkMacSystemFont, \"Segoe UI\",\n      \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Helvetica Neue\", Arial, sans-serif}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let htmlhead;
	let t;
	let htmlpage;
	let current;
	htmlhead = new HtmlHead({});
	htmlpage = new HtmlPage({ props: { name: /*name*/ ctx[0] } });

	return {
		c() {
			create_component(htmlhead.$$.fragment);
			t = space();
			create_component(htmlpage.$$.fragment);
		},
		m(target, anchor) {
			mount_component(htmlhead, target, anchor);
			insert(target, t, anchor);
			mount_component(htmlpage, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const htmlpage_changes = {};
			if (dirty & /*name*/ 1) htmlpage_changes.name = /*name*/ ctx[0];
			htmlpage.$set(htmlpage_changes);
		},
		i(local) {
			if (current) return;
			transition_in(htmlhead.$$.fragment, local);
			transition_in(htmlpage.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(htmlhead.$$.fragment, local);
			transition_out(htmlpage.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(htmlhead, detaching);
			if (detaching) detach(t);
			destroy_component(htmlpage, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { name } = $$props;

	$$self.$$set = $$props => {
		if ("name" in $$props) $$invalidate(0, name = $$props.name);
	};

	return [name];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-caqnf-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { name: 0 });
	}
}

export default App;
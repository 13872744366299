/* js/component/module/CityModuleSelection.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	get_spread_object,
	get_spread_update,
	globals,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

const { document: document_1 } = globals;
import { onMount } from "svelte";
import CityModule from "./CityModule.svelte";
import { config } from "../../config/index.js";

import {
	getValueFromSessionStore,
	saveToSessionStore
} from "../../common/functions.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-13qdh7w-style";
	style.textContent = "#tools.svelte-13qdh7w{display:flex;flex-direction:row;align-items:center;flex-wrap:wrap;overflow:auto;width:150px;height:690px}.scroll.svelte-13qdh7w::-webkit-scrollbar{width:10px}.scroll.svelte-13qdh7w::-webkit-scrollbar-thumb{background:#ffffff}.scroll.svelte-13qdh7w::-webkit-scrollbar-track{background:#666}@media only screen and (max-width: 640px){#tools.svelte-13qdh7w{display:flex;flex-direction:column;align-items:center;overflow:auto;width:910px}}";
	append(document_1.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	child_ctx[9] = i;
	return child_ctx;
}

// (83:2) {#each items as item, count}
function create_each_block(ctx) {
	let citymodule;
	let current;
	const citymodule_spread_levels = [/*item*/ ctx[7], { count: /*count*/ ctx[9] }];
	let citymodule_props = {};

	for (let i = 0; i < citymodule_spread_levels.length; i += 1) {
		citymodule_props = assign(citymodule_props, citymodule_spread_levels[i]);
	}

	citymodule = new CityModule({ props: citymodule_props });
	citymodule.$on("select", /*onSelect*/ ctx[1]);

	return {
		c() {
			create_component(citymodule.$$.fragment);
		},
		m(target, anchor) {
			mount_component(citymodule, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const citymodule_changes = (dirty & /*items*/ 1)
			? get_spread_update(citymodule_spread_levels, [get_spread_object(/*item*/ ctx[7]), citymodule_spread_levels[1]])
			: {};

			citymodule.$set(citymodule_changes);
		},
		i(local) {
			if (current) return;
			transition_in(citymodule.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(citymodule.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(citymodule, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current;
	let each_value = /*items*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "id", "tools");
			attr(div, "class", "scroll svelte-13qdh7w");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*items, onSelect*/ 3) {
				each_value = /*items*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self) {
	const { texHeight, texWidth } = config;
	let items = [];
	let lastSelectedElement;

	for (let y = 0; y < texHeight; y++) {
		for (let x = 0; x < texWidth; x++) {
			items.push({ x, y });
		}
	}

	const _store = item => {
		const { posY, posX } = item.dataset;

		saveToSessionStore("cityModule", JSON.stringify({
			posY,
			posX,
			id: item.id,
			offset: item.offsetTop
		}));
	};

	const onSelect = ({ detail: selectedElement }) => {
		if (!selectedElement.classList.contains("active")) {
			selectedElement.classList.add("active");
			_store(selectedElement);
		}

		if (lastSelectedElement && lastSelectedElement != selectedElement) {
			lastSelectedElement.classList.remove("active");
		}

		lastSelectedElement = selectedElement;
	};

	const scrollToLastSelected = () => {
		const item = getValueFromSessionStore("cityModule");

		if (item) {
			const { offset, id } = JSON.parse(item);
			document.getElementById("tools").scrollTop = offset;

			if (id && document.getElementById(id)) {
				document.getElementById(id).click();
			}
		}
	};

	onMount(async () => scrollToLastSelected());
	return [items, onSelect];
}

class CityModuleSelection extends SvelteComponent {
	constructor(options) {
		super();
		if (!document_1.getElementById("svelte-13qdh7w-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default CityModuleSelection;
import { config } from "../config/index.js";
import { ToBase64, createMatrix, FromBase64 } from "./functions.js";

export const convertMapToString = map => {
  let c = 0;
  const { ntiles, texWidth } = config;
  const u8 = new Uint8Array(ntiles * ntiles);
  for (let i = 0; i < ntiles; i++) {
    for (let j = 0; j < ntiles; j++) {
      u8[c++] = map[i][j][0] * texWidth + map[i][j][1];
    }
  }
  return ToBase64(u8);
};

export const convertStringToMap = token => {
  const {
    ntiles,
    texWidth,
    map: { initial }
  } = config;
  let map = createMatrix(initial);
  if (!token || token.length < 3) {
    return map;
  }
  try {
    const u8 = FromBase64(token);
    let c = 0;
    for (let i = 0; i < ntiles; i++) {
      for (let j = 0; j < ntiles; j++) {
        const t = u8[c++] || 0;
        const x = Math.trunc(t / texWidth);
        const y = Math.trunc(t % texWidth);
        map[i][j] = [x, y];
      }
    }
  } catch (e) {
    console.error;
  }
  return map;
};

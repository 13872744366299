/* js/component/module/CityModule.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import { createEventDispatcher } from "svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-1bmgc3e-style";
	style.textContent = "div.svelte-1bmgc3e{display:block;background-image:url(\"../../../img/01_130x66_130x230.png\");background-repeat:no-repeat;width:130px;height:230px;border:2px solid var(--main-bg-color)}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;
	let div_id_value;
	let div_style_value;

	return {
		c() {
			div = element("div");
			attr(div, "id", div_id_value = "city-module-" + /*count*/ ctx[2]);
			attr(div, "data-pos-x", /*x*/ ctx[0]);
			attr(div, "data-pos-y", /*y*/ ctx[1]);
			attr(div, "style", div_style_value = `background-position: -${/*x*/ ctx[0] * 130}px -${/*y*/ ctx[1] * 230}px`);
			attr(div, "class", "svelte-1bmgc3e");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*count*/ 4 && div_id_value !== (div_id_value = "city-module-" + /*count*/ ctx[2])) {
				attr(div, "id", div_id_value);
			}

			if (dirty & /*x*/ 1) {
				attr(div, "data-pos-x", /*x*/ ctx[0]);
			}

			if (dirty & /*y*/ 2) {
				attr(div, "data-pos-y", /*y*/ ctx[1]);
			}

			if (dirty & /*x, y*/ 3 && div_style_value !== (div_style_value = `background-position: -${/*x*/ ctx[0] * 130}px -${/*y*/ ctx[1] * 230}px`)) {
				attr(div, "style", div_style_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { x } = $$props, { y } = $$props, { count } = $$props;
	const dispatch = createEventDispatcher();
	const select = ({ target }) => dispatch("select", target);

	$$self.$$set = $$props => {
		if ("x" in $$props) $$invalidate(0, x = $$props.x);
		if ("y" in $$props) $$invalidate(1, y = $$props.y);
		if ("count" in $$props) $$invalidate(2, count = $$props.count);
	};

	return [x, y, count];
}

class CityModule extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1bmgc3e-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { x: 0, y: 1, count: 2 });
	}
}

export default CityModule;
/* js/component/map/Foreground.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal
} from "svelte/internal";

import { onMount } from "svelte";
import { config } from "../../config/index.js";
import { store } from "../../store/index.js";
import { setValue, saveMap } from "../../store/actions.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-kcvvmm-style";
	style.textContent = "canvas.svelte-kcvvmm{display:block;position:absolute;top:0;left:0}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let canvas_1;
	let mounted;
	let dispose;

	return {
		c() {
			canvas_1 = element("canvas");
			attr(canvas_1, "id", "fg");
			attr(canvas_1, "width", /*width*/ ctx[1]);
			attr(canvas_1, "height", /*height*/ ctx[2]);
			attr(canvas_1, "class", "svelte-kcvvmm");
		},
		m(target, anchor) {
			insert(target, canvas_1, anchor);
			/*canvas_1_binding*/ ctx[6](canvas_1);

			if (!mounted) {
				dispose = [
					listen(canvas_1, "contextmenu", contextmenu_handler),
					listen(canvas_1, "mouseup", /*unclick*/ ctx[4]),
					listen(canvas_1, "mousedown", /*click*/ ctx[3]),
					listen(canvas_1, "touchend", /*click*/ ctx[3]),
					listen(canvas_1, "pointerup", /*click*/ ctx[3]),
					listen(canvas_1, "mousemove", /*viz*/ ctx[5])
				];

				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(canvas_1);
			/*canvas_1_binding*/ ctx[6](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

const contextmenu_handler = e => e.preventDefault();

function instance($$self, $$props, $$invalidate) {
	const { texture, tileHeight, w, canvas: { width, height } } = config;
	let canvas, context, isPlacing;

	const click = e => {
		const { w, h, ntiles } = config;
		const pos = getPosition(e);
		const data = window.sessionStorage.getItem("cityModule");

		if (data && pos.x >= 0 && pos.x < ntiles && pos.y >= 0 && pos.y < ntiles) {
			const { posY, posX } = JSON.parse(data);
			const value = e.which === 3 ? [0, 0] : [Number(posY), Number(posX)];
			isPlacing = true;
			store.dispatch(setValue(pos.x, pos.y, value));
			context.clearRect(-w, -h, w * 2, h * 2);
			store.dispatch(saveMap(store.getState().map));
		}
	};

	const unclick = _ => {
		if (isPlacing) isPlacing = false;
	};

	const drawTile = (c, x, y, color) => {
		const { tileWidth, tileHeight } = config;
		c.save();
		c.translate((y - x) * tileWidth / 2, (x + y) * tileHeight / 2);
		c.beginPath();
		c.moveTo(0, 0);
		c.lineTo(tileWidth / 2, tileHeight / 2);
		c.lineTo(0, tileHeight);
		c.lineTo(-tileWidth / 2, tileHeight / 2);
		c.closePath();
		c.fillStyle = color;
		c.fill();
		c.restore();
	};

	const getPosition = e => {
		let x = e.offsetX;
		const { ntiles, tileWidth, tileHeight } = config;
		let y = e.offsetY - tileHeight * 2;
		const _y = y / (ntiles * tileHeight / ntiles);
		const _x = (x - tileWidth / 2) / (ntiles * tileWidth / ntiles) - ntiles / 2;
		x = Math.round(_y - _x - 1);
		y = Math.round(_x + _y);
		return { x, y };
	};

	const viz = e => {
		if (isPlacing) {
			click(e);
		}

		const { w, h, ntiles } = config;
		const pos = getPosition(e);
		context.clearRect(-w, -h, w * 2, h * 2);

		if (pos.x >= 0 && pos.x < ntiles && pos.y >= 0 && pos.y < ntiles) {
			drawTile(context, pos.x, pos.y, "rgba(0,0,0,0.2)");
		}
	};

	onMount(() => {
		const backgroundImage = new Image();

		backgroundImage.onload = () => {
			context = canvas.getContext("2d");
			context.translate(w / 2, tileHeight * 2);
		};

		backgroundImage.src = texture.src;
	});

	function canvas_1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			canvas = $$value;
			$$invalidate(0, canvas);
		});
	}

	return [canvas, width, height, click, unclick, viz, canvas_1_binding];
}

class Foreground extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-kcvvmm-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Foreground;
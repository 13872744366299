/* js/component/slider/Slider.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import { tns } from "tiny-slider/src/tiny-slider";
import Slide from "./Slide.svelte";
import { onMount } from "svelte";
import { getCities } from "../../common/functions.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-1prfb0m-style";
	style.textContent = ".slider-container.svelte-1prfb0m{width:1000px;margin:auto;padding:0}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	child_ctx[6] = i;
	return child_ctx;
}

// (38:6) {:else}
function create_else_block(ctx) {
	let slide;
	let current;
	slide = new Slide({ props: { content: `${/*city*/ ctx[4]}` } });

	return {
		c() {
			create_component(slide.$$.fragment);
		},
		m(target, anchor) {
			mount_component(slide, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const slide_changes = {};
			if (dirty & /*cities*/ 2) slide_changes.content = `${/*city*/ ctx[4]}`;
			slide.$set(slide_changes);
		},
		i(local) {
			if (current) return;
			transition_in(slide.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(slide.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(slide, detaching);
		}
	};
}

// (36:6) {#if index < cities.length - 1}
function create_if_block(ctx) {
	let slide;
	let current;
	slide = new Slide({ props: { content: `${/*city*/ ctx[4]}` } });

	return {
		c() {
			create_component(slide.$$.fragment);
		},
		m(target, anchor) {
			mount_component(slide, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const slide_changes = {};
			if (dirty & /*cities*/ 2) slide_changes.content = `${/*city*/ ctx[4]}`;
			slide.$set(slide_changes);
		},
		i(local) {
			if (current) return;
			transition_in(slide.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(slide.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(slide, detaching);
		}
	};
}

// (35:4) {#each cities as city, index}
function create_each_block(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*index*/ ctx[6] < /*cities*/ ctx[1].length - 1) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let current;
	let each_value = /*cities*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "slider");
			attr(div1, "class", "slider-container svelte-1prfb0m");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			/*div1_binding*/ ctx[2](div1);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*cities*/ 2) {
				each_value = /*cities*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div0, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_each(each_blocks, detaching);
			/*div1_binding*/ ctx[2](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let sliderDiv;
	let cities = [];

	const initSlider = _ => {
		tns({
			container: `.slider`,
			items: 4,
			slideBy: "page",
			autoplay: false,
			nav: false,
			liveregion: false
		});
	};

	onMount(async () => {
		const result = await getCities();
		$$invalidate(1, cities = result.slice(0, 100));
		setTimeout(initSlider);
	});

	function div1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			sliderDiv = $$value;
			$$invalidate(0, sliderDiv);
		});
	}

	return [sliderDiv, cities, div1_binding];
}

class Slider extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1prfb0m-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Slider;
import { convertMapToString, convertStringToMap } from "../common/converter.js";
import { getHashParams, updateHashParam } from "../common/functions.js";

export const ActionTypes = {
  SAVE: "save",
  LOAD: "load",
  SET_VALUE: "setValue"
};

export const setValue = (x, y, value) => {
  return {
    x,
    y,
    value,
    type: ActionTypes.SET_VALUE
  };
};

export const saveMap = map => {
  const currentHash = document.location.hash || "#";
  const state = convertMapToString(map);
  const newHash = updateHashParam(currentHash, "m", state);
  history.replaceState(undefined, undefined, newHash);
  return {
    type: ActionTypes.SAVE
  };
};

export const loadMap = () => {
  const { m: stateToken } = getHashParams(document.location.hash);
  const map = convertStringToMap(stateToken);
  return {
    map,
    type: ActionTypes.LOAD
  };
};

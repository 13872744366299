/* js/component/html/HtmlHead.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	noop,
	safe_not_equal
} from "svelte/internal";

function create_fragment(ctx) {
	let meta0;
	let meta1;
	let meta2;
	let meta3;
	let meta4;
	let meta5;
	let meta6;
	let meta7;
	let meta8;
	let meta9;
	let meta10;
	let meta11;
	let link0;
	let link1;
	let link2;
	let link3;

	return {
		c() {
			meta0 = element("meta");
			meta1 = element("meta");
			meta2 = element("meta");
			meta3 = element("meta");
			meta4 = element("meta");
			meta5 = element("meta");
			meta6 = element("meta");
			meta7 = element("meta");
			meta8 = element("meta");
			meta9 = element("meta");
			meta10 = element("meta");
			meta11 = element("meta");
			link0 = element("link");
			link1 = element("link");
			link2 = element("link");
			link3 = element("link");
			document.title = "City Builder";
			attr(meta0, "name", "Description");
			attr(meta0, "content", "Isometric City Builder");
			attr(meta1, "charset", "UTF-8");
			attr(meta2, "name", "viewport");
			attr(meta2, "content", "width=device-width, initial-scale=1.0");
			attr(meta3, "name", "theme-color");
			attr(meta3, "content", "#FFF");
			attr(meta4, "name", "mobile-web-app-capable");
			attr(meta4, "content", "yes");
			attr(meta5, "property", "og:type");
			attr(meta5, "content", "Article");
			attr(meta6, "property", "og:title");
			attr(meta6, "content", "IsoCity");
			attr(meta7, "property", "og:author");
			attr(meta7, "content", "original author: Victor Ribeiro");
			attr(meta8, "property", "og:description");
			attr(meta8, "content", "Isometric City Builder");
			attr(meta9, "property", "og:image");
			attr(meta9, "content", "img/favicon.png");
			attr(meta10, "property", "og:image:width");
			attr(meta10, "content", "512");
			attr(meta11, "property", "og:image:height");
			attr(meta11, "content", "512");
			attr(link0, "rel", "stylesheet");
			attr(link0, "href", "css/main.css");
			attr(link1, "rel", "stylesheet");
			attr(link1, "type", "text/css");
			attr(link1, "href", "https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/tiny-slider.css");
			attr(link2, "rel", "icon");
			attr(link2, "href", "img/favicon.png");
			attr(link2, "sizes", "256x256");
			attr(link3, "rel", "apple-touch-icon");
			attr(link3, "href", "img/favicon.png");
		},
		m(target, anchor) {
			append(document.head, meta0);
			append(document.head, meta1);
			append(document.head, meta2);
			append(document.head, meta3);
			append(document.head, meta4);
			append(document.head, meta5);
			append(document.head, meta6);
			append(document.head, meta7);
			append(document.head, meta8);
			append(document.head, meta9);
			append(document.head, meta10);
			append(document.head, meta11);
			append(document.head, link0);
			append(document.head, link1);
			append(document.head, link2);
			append(document.head, link3);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			detach(meta0);
			detach(meta1);
			detach(meta2);
			detach(meta3);
			detach(meta4);
			detach(meta5);
			detach(meta6);
			detach(meta7);
			detach(meta8);
			detach(meta9);
			detach(meta10);
			detach(meta11);
			detach(link0);
			detach(link1);
			detach(link2);
			detach(link3);
		}
	};
}

class HtmlHead extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default HtmlHead;
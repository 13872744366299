/* js/component/map/CityMap.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { onMount } from "svelte";
import Background from "./Background.svelte";
import Foreground from "./Foreground.svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-5kwjk8-style";
	style.textContent = "#area.svelte-5kwjk8{position:relative;width:910px;height:690px}@media only screen and (max-width: 640px){#area.svelte-5kwjk8{position:relative;max-width:100vw}}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let background;
	let t;
	let foreground;
	let current;
	background = new Background({});
	foreground = new Foreground({});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			create_component(background.$$.fragment);
			t = space();
			create_component(foreground.$$.fragment);
			attr(div0, "id", "area");
			attr(div0, "class", "svelte-5kwjk8");
			attr(div1, "class", "map");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			mount_component(background, div0, null);
			append(div0, t);
			mount_component(foreground, div0, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(background.$$.fragment, local);
			transition_in(foreground.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(background.$$.fragment, local);
			transition_out(foreground.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(background);
			destroy_component(foreground);
		}
	};
}

class CityMap extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-5kwjk8-style")) add_css();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default CityMap;
export const $ = _ => document.querySelector(_);

export const $c = _ => document.createElement(_);

// From https://stackoverflow.com/a/36046727
export const ToBase64 = u8 => {
  return btoa(String.fromCharCode.apply(null, u8));
};
export const FromBase64 = str => {
  return atob(str)
    .split("")
    .map(c => c.charCodeAt(0));
};

export const createMatrix = ({ token = "", defaultValue = [0, 0] }) => {
  return Array.from(token).map(_ => Array.from(token).map(_ => defaultValue));
};

export const createTexture = ({ src }) => {
  const texture = new Image();
  texture.src = src;
  return texture;
};

export const registerCustomElements = collection => {
  collection
    .map(ele => Object.entries(ele).flat())
    .forEach(([key, value]) => customElements.define(key, value));
};

export const updateHashParam = (hash, key, value) => {
  var re = new RegExp("([&#])" + key + "=.*?(&|$)", "i");
  if (hash.match(re)) {
    return hash.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return hash.includes("&")
      ? `${hash}&${key}=${value}`
      : `${hash}${key}=${value}`;
  }
};

export const getHashParams = hashToken => {
  let hashes = hashToken.replace("#", "").split("&");
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split("=");
    return { ...params, ...{ [key]: decodeURIComponent(val) } };
  }, {});
};

export const saveToSessionStore = (key, value) =>
  window.sessionStorage.setItem(key, value);

export const getValueFromSessionStore = key =>
  window.sessionStorage.getItem(key);

export const getCities = async () => {
  const res = await fetch(
    `https://restcountries.eu/rest/v2/all?fields=capital`
  );
  const result = await res.json();
  return result
    .map(({ capital }) => capital)
    .filter(city => city.trim().length > 0);
};

import { createStore } from "redux";
//"https://cdn.pika.dev/-/redux/4.0.4/dist-es2019/redux.min.js";
import { config } from "../config/index.js";
import { ActionTypes } from "./actions.js";
import { createMatrix, createTexture } from "../common/functions.js";

const createInitialState = () => {
  return {
    map: createMatrix(config.map.initial),
    texture: createTexture(config.texture)
  };
};

const reducer = (state = createInitialState(), action) => {
  const { type } = action;
  const { LOAD, SAVE, SET_VALUE } = ActionTypes;
  switch (type) {
    case LOAD:
      return { ...state, ...{ map: action.map } };
    case SET_VALUE:
      const newMap = [...state.map];
      newMap[action.x][action.y] = action.value;
      return { ...state, ...{ map: newMap } };
    case SAVE:
      return state;
    default:
      return state;
  }
};

export const store = createStore(reducer);

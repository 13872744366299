/* js/component/builder/CityBuilder.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import CityModuleSelection from "../module/CityModuleSelection.svelte";
import CityMap from "../map/CityMap.svelte";
import Slider from "../slider/Slider.svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-1n7xe1n-style";
	style.textContent = "#main.svelte-1n7xe1n{position:relative;display:flex;align-items:flex-start;justify-content:space-around}@media only screen and (max-width: 640px){#main.svelte-1n7xe1n{position:relative;display:flex;flex-direction:column;align-items:flex-start}}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let section;
	let citymoduleselection;
	let t0;
	let citymap;
	let t1;
	let slider;
	let current;
	citymoduleselection = new CityModuleSelection({});
	citymap = new CityMap({});
	slider = new Slider({});

	return {
		c() {
			section = element("section");
			create_component(citymoduleselection.$$.fragment);
			t0 = space();
			create_component(citymap.$$.fragment);
			t1 = space();
			create_component(slider.$$.fragment);
			attr(section, "id", "main");
			attr(section, "class", "svelte-1n7xe1n");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			mount_component(citymoduleselection, section, null);
			append(section, t0);
			mount_component(citymap, section, null);
			insert(target, t1, anchor);
			mount_component(slider, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(citymoduleselection.$$.fragment, local);
			transition_in(citymap.$$.fragment, local);
			transition_in(slider.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(citymoduleselection.$$.fragment, local);
			transition_out(citymap.$$.fragment, local);
			transition_out(slider.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_component(citymoduleselection);
			destroy_component(citymap);
			if (detaching) detach(t1);
			destroy_component(slider, detaching);
		}
	};
}

class CityBuilder extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1n7xe1n-style")) add_css();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default CityBuilder;
export const config = {
  texWidth: 12,
  texHeight: 6,
  ntiles: 7,
  w: 910,
  h: 462,
  tileWidth: 128,
  tileHeight: 64,
  texture: {
    src: "img/01_130x66_130x230.png"
  },
  canvas: {
    width: 910,
    height: 666
  },
  map: {
    initial: {
      token: "1234567",
      defaultValue: [0, 0]
    }
  }
};

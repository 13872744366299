/* js/component/slider/Slide.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	text
} from "svelte/internal";

function create_fragment(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*content*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, [dirty]) {
			if (dirty & /*content*/ 1) set_data(t, /*content*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { content } = $$props;

	$$self.$$set = $$props => {
		if ("content" in $$props) $$invalidate(0, content = $$props.content);
	};

	return [content];
}

class Slide extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { content: 0 });
	}
}

export default Slide;
/* js/component/html/Header.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-fd2r7d-style";
	style.textContent = "header.svelte-fd2r7d{text-align:center}h3.svelte-fd2r7d{display:contents}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let header;
	let span;
	let t1;
	let h3;
	let t2;

	return {
		c() {
			header = element("header");
			span = element("span");
			span.textContent = "City:";
			t1 = space();
			h3 = element("h3");
			t2 = text(/*name*/ ctx[0]);
			attr(h3, "class", "svelte-fd2r7d");
			attr(header, "class", "svelte-fd2r7d");
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, span);
			append(header, t1);
			append(header, h3);
			append(h3, t2);
		},
		p(ctx, [dirty]) {
			if (dirty & /*name*/ 1) set_data(t2, /*name*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(header);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { name } = $$props;

	$$self.$$set = $$props => {
		if ("name" in $$props) $$invalidate(0, name = $$props.name);
	};

	return [name];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-fd2r7d-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { name: 0 });
	}
}

export default Header;
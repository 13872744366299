/* js/component/map/Background.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import { onMount } from "svelte";
import { config } from "../../config/index.js";
import { store } from "../../store/index.js";
import { loadMap } from "../../store/actions.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-kcvvmm-style";
	style.textContent = "canvas.svelte-kcvvmm{display:block;position:absolute;top:0;left:0}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let canvas_1;

	return {
		c() {
			canvas_1 = element("canvas");
			attr(canvas_1, "id", "bg");
			attr(canvas_1, "width", /*width*/ ctx[1]);
			attr(canvas_1, "height", /*height*/ ctx[2]);
			attr(canvas_1, "class", "svelte-kcvvmm");
		},
		m(target, anchor) {
			insert(target, canvas_1, anchor);
			/*canvas_1_binding*/ ctx[3](canvas_1);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(canvas_1);
			/*canvas_1_binding*/ ctx[3](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const { texture, tileHeight, w, canvas: { width, height } } = config;
	let canvas, context;

	const drawMap = _ => {
		const { w, h, ntiles } = config;
		const { map, texture } = store.getState();
		context.clearRect(-w, -h, w * 2, h * 2);

		for (let i = 0; i < ntiles; i++) {
			for (let j = 0; j < ntiles; j++) {
				const entry = map[i][j];
				_drawImageTile(context, i, j, entry[0], entry[1], texture);
			}
		}
	};

	const _drawImageTile = (c, x, y, i, j, texture) => {
		const { tileWidth, tileHeight } = config;
		c.save();
		c.translate((y - x) * tileWidth / 2, (x + y) * tileHeight / 2);
		j *= 130;
		i *= 230;
		c.drawImage(texture, j, i, 130, 230, -65, -130, 130, 230);
		c.restore();
	};

	onMount(() => {
		const backgroundImage = new Image();

		backgroundImage.onload = () => {
			context = canvas.getContext("2d");
			context.translate(w / 2, tileHeight * 2);
			store.subscribe(drawMap);
			store.dispatch(loadMap());
		};

		backgroundImage.src = texture.src;
	});

	function canvas_1_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			canvas = $$value;
			$$invalidate(0, canvas);
		});
	}

	return [canvas, width, height, canvas_1_binding];
}

class Background extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-kcvvmm-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Background;
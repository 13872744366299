/* js/component/html/HtmlPage.svelte generated by Svelte v3.29.0 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import CityBuilder from "../builder/CityBuilder.svelte";
import HtmlHead from "./HtmlHead.svelte";
import Header from "./Header.svelte";

function create_fragment(ctx) {
	let header;
	let t;
	let citybuilder;
	let current;
	header = new Header({ props: { name: /*name*/ ctx[0] } });
	citybuilder = new CityBuilder({});

	return {
		c() {
			create_component(header.$$.fragment);
			t = space();
			create_component(citybuilder.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			insert(target, t, anchor);
			mount_component(citybuilder, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const header_changes = {};
			if (dirty & /*name*/ 1) header_changes.name = /*name*/ ctx[0];
			header.$set(header_changes);
		},
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(citybuilder.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(citybuilder.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(header, detaching);
			if (detaching) detach(t);
			destroy_component(citybuilder, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { name } = $$props;

	$$self.$$set = $$props => {
		if ("name" in $$props) $$invalidate(0, name = $$props.name);
	};

	return [name];
}

class HtmlPage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { name: 0 });
	}
}

export default HtmlPage;